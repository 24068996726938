/* eslint-disable max-len */
import React, { useCallback } from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { Dropdown, MediaQuery } from '@sonnen/shared-web';

import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Market } from '+utils/market.util';

import { NavLink } from '../NavLink';
import { NavLinkColor } from '../NavLink/NavLink.helpers';
import { StatusTileColors } from '../StatusTile';
import { StatusTile } from '../StatusTile/StatusTile.component';
import { YourSonnenWorldTrigger } from './YourSonnenWorldTrigger';

import './YourSonnenWorldTab.scss';

type ActionLinks = {
  partnerApp: string;
  marketingSupport: string;
  documentsGeneral: string;
  documentsService: string;
  eLearning: string;
  releaseNotes: string;
  installationManuals: string;
};

const linkTestIdMap: Record<keyof ActionLinks, string> = {
  partnerApp: 'partnerapp-subtab',
  marketingSupport: 'marketing-documents-subtab',
  documentsGeneral: 'general-documents-subtab',
  documentsService: 'technical-and-service-documents-subtab',
  eLearning: 'e-academy-subtab',
  releaseNotes: 'release-notes-subtab',
  installationManuals: 'installation-manuals-subtab',
};

const GERMAN_ACTION_LINKS: Partial<ActionLinks> = {
  partnerApp: 'https://sonnen.de/partner-app/',
  marketingSupport: 'https://marketing-support.sonnen.de',
  documentsGeneral: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport?language=de',
  documentsService: 'https://sonnen.my.site.com/partnerportal/s/helpandsupport?language=de',
  eLearning: 'https://sonnen.breuerundnohr.de',
  releaseNotes: 'https://sonnengroup.com/rln-sb-partner',
};

const marketActionLinkMap: Record<Market, Partial<ActionLinks>> = {
  [Market.DE]: GERMAN_ACTION_LINKS,
  [Market.AT]: GERMAN_ACTION_LINKS,
  [Market.CH]: GERMAN_ACTION_LINKS,
  [Market.IT]: {
    eLearning: 'https://www.tecnoacademy.it/sonnen-academy-installatori/',
    releaseNotes: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.GME]: {
    marketingSupport: 'https://marketing-support.sonnen.de',
    releaseNotes: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.ES]: {
    marketingSupport: 'https://marketing-support.sonnen.de',
    releaseNotes: 'https://sonnengroup.com/rln-sb-partner',
  },
  [Market.AU]: {
    documentsGeneral: 'https://owncloud.sonnenbatterie.de/f/24117913',
    documentsService: 'https://www.sonnensupportaustralia.com.au',
  },
  [Market.US]: {
    marketingSupport:
      'https://sonnnenbatterieus-my.sharepoint.com/:f:/g/personal/sbi_marketing_sonnen_team/EvDbwA-9fTBFsacFg6hKqooBuRJlcKQLCICTUGG5k-CAQQ?e=DfbNPB',
    installationManuals: 'https://sonnengroup.com/installation-manual/',
    documentsService:
      'https://sonnnenbatterieus-my.sharepoint.com/:f:/g/personal/sbi_marketing_sonnen_team/EtfP-nAEWdRNus9Qpmn3H64ByTgVT68EmUqe47Ma_NDZtQ?e=kZPa3m',
    eLearning: 'https://sonnenuniversity.talentlms.com/index',
  },
};

const setDropdownContentClass = (isDesktop: boolean) =>
  classNames(
    'your-sonnen-world-tab__dropdown-content',
    `your-sonnen-world-tab__dropdown-content--${isDesktop ? 'desktop' : 'mobile'}`
  );

export const YourSonnenWorldTab = ({ market }: { market: Market }) => {
  const listItems = marketActionLinkMap[market];
  const { eAcademyDisabled } = useFlags();

  const generateNavLink = useCallback(
    ([key, value]: [key: string, value: string | null]) => {
      if (!value) return null;

      const keyAction = key as keyof ActionLinks;

      const testId = linkTestIdMap[keyAction];
      const titleKey = T.general.navigation[keyAction];
      const title = I18n.t(titleKey);

      if (
        key === 'eLearning' &&
        eAcademyDisabled &&
        [Market.DE, Market.AT, Market.CH].flat().includes(market)
      )
        return null;

      return (
        <NavLink
          key={key}
          action={value}
          type="mobile"
          border={true}
          navLinkColor={NavLinkColor.WHITE}
          dataTestId={testId}
        >
          {title}
          {key === 'partnerApp' && (
            <StatusTile
              label={I18n.t(T.general.navigation.sonnenDriveLabel)}
              color={StatusTileColors.BLUE}
              additionalClass="nav-status-tile"
            />
          )}
        </NavLink>
      );
    },
    [eAcademyDisabled, market]
  );

  if (!marketActionLinkMap[market]) return null;

  return (
    <Media query={MediaQuery.UP_NAV}>
      {(isDesktop: boolean) => (
        <NavLink navLinkColor={NavLinkColor.WHITE} dataTestId="your-sonnen-world-tab">
          <Dropdown
            className="your-sonnen-world-tab"
            trigger={<YourSonnenWorldTrigger />}
            isOpen={false}
          >
            <div className={setDropdownContentClass(isDesktop)}>
              <ul className="your-sonnen-world-tab__navigation">
                {Object.entries(listItems).map(generateNavLink)}
              </ul>
            </div>
          </Dropdown>
        </NavLink>
      )}
    </Media>
  );
};
