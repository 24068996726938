import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import { mapBatteryOperatingModeToLabel } from '+app/+customer/store/+customer.helper';
import { BatteryOperatingMode, BatteryOperatingModeOptions } from '+shared/store/site/types';

import { ExtendedHeaderLabelProps } from '../../components/BatteryOperationsHeader';

export const mapBatteryOperatingModeToTag = (
  isPending: boolean,
  batteryOperatingMode?: BatteryOperatingMode
): ExtendedHeaderLabelProps | undefined => {
  const isNotDefined = isNil(batteryOperatingMode);

  if (isNotDefined) {
    return;
  }

  if (isPending) {
    return {
      text: I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.changeOperatingMode.statusPending
      ),
      variant: 'gray',
    };
  }

  return {
    text: mapBatteryOperatingModeToLabel(batteryOperatingMode),
    variant:
      batteryOperatingMode === BatteryOperatingModeOptions.MODULE_EXTENSION ? 'yellow' : 'blue',
  };
};
