import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Loadable } from '@sonnen/shared-web';

import classNames from 'classnames';

import { PUBLIC_ROUTES } from '+app/router';
import { isRestrictedRoute, matchPath } from '+router/store/router.helpers';
import { getRouterLocationPath } from '+router/store/router.selectors';
import { NotificationOutlet } from '+shared/containers';
import { isLanguageLoading, isModalOpen } from '+shared/store/layout/layout.selectors';

import { ImpostorBanner } from '../../components';
import { isAuthenticated, isImpersonated } from '../../store/auth/auth.selectors';
import { StoreState } from '../../store/store.interface';
import { LayoutFooter } from '../LayoutFooter';
import { LayoutNav } from '../LayoutNav/LayoutNav.component';
import { LayoutBackground, LayoutContext } from './Layout.context';

import './Layout.component.scss';

interface ComponentProps {
  children: React.ReactNode;
  isBootstrapped?: boolean;
}

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: isAuthenticated(state),
  isImpersonated: isImpersonated(state),
  path: getRouterLocationPath(state),
  isModalOpen: isModalOpen(state),
  isLanguageLoading: isLanguageLoading(state),
});

type Props = ComponentProps & ReturnType<typeof mapStateToProps> & RouteComponentProps;

interface State {
  background: LayoutBackground;
}

export class LayoutComponent extends React.PureComponent<Props, State> {
  readonly state: State = {
    background: LayoutBackground.WHITE,
  };

  setBackground = (background: LayoutBackground) => this.setState({ background });

  resetBackground = () => this.setState({ background: LayoutBackground.WHITE });

  isInFrame = () => {
    try {
      return window.self !== window.top;
    } catch (e: any) {
      return true;
    }
  };

  render() {
    const { children, isBootstrapped, isAuthenticated, isImpersonated, path, isModalOpen } =
      this.props;
    const { background } = this.state;

    const isRegisterContext =
      matchPath([
        ...PUBLIC_ROUTES.REGISTER,
        ...PUBLIC_ROUTES.REGISTER_FAILURE,
        ...PUBLIC_ROUTES.REGISTER_SUCCESS,
      ])(path) && !isAuthenticated;
    const isRestrictedContext = isRestrictedRoute(path);
    const isLoaded = isBootstrapped && (isRestrictedContext ? isAuthenticated : true);

    // SON-42494 - Allow the Partner Portal to be embedded within Toolbox iframe
    const contentOnly: boolean =
      this.isInFrame() || new URLSearchParams(window.location.search).get('contentOnly') === 'true';

    return (
      <LayoutContext.Provider
        value={{
          setBackground: this.setBackground,
          resetBackground: this.resetBackground,
        }}
      >
        <div
          className={classNames('c-layout', {
            'c-layout__with-bg': isRegisterContext,
            'c-layout__content-only': contentOnly,
          })}
        >
          {!isRegisterContext && (
            <>
              <LayoutNav />
              {isImpersonated && <ImpostorBanner />}
            </>
          )}
          {isModalOpen && <div className={'c-layout__modal-overlay'} />}
          <main
            className={classNames('c-layout__main', {
              'c-layout__main--higher': isRegisterContext,
              'c-layout__main--bg-gray': background === LayoutBackground.GRAY,
            })}
          >
            <NotificationOutlet />
            <Loadable predicate={!isLoaded}>{children}</Loadable>
          </main>
          <LayoutFooter isTransparent={isRegisterContext} />
        </div>
      </LayoutContext.Provider>
    );
  }
}

export const Layout = connect(mapStateToProps)(withRouter(LayoutComponent));
