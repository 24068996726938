import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  AnalysisLastXYSeriePosition,
  AnalysisLiveBubbles,
  AnalysisTooltip,
  BASE_TICK_COUNT,
  BatteryStatusesKey,
  Card,
  CellDataSeriesKey,
  EnergyFlowSeriesKey,
  Icon,
  InfoBanner,
  MediaQuery,
  prepareTooltipData,
  useCountryFeature,
} from '@sonnen/shared-web';

import {
  DataContainer,
  DataContainerTooltipExtension,
  DataContainerTransformExtension,
  SimpleOnScaleListener,
  TooltipEvent,
  TooltipEventHandler,
  XYPoint,
} from '@kanva/charts';
import { Point, View, Visibility } from '@kanva/core';
import * as classNames from 'classnames';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { isEmpty, isEqual } from 'lodash';
import { compose, isNil } from 'lodash/fp';

import { getSelectedCustomerQueryStatus } from '+app/+customer/store/+customer.selectors';
import { DAYS_IN_WEEK, DAYS_IN_YEAR, HOURS_IN_DAY } from '+app/App.constants';
import { KNOWLEDGE_BASE } from '+config/env.config';
import { CountryFeatureName } from '+config/index';
import {
  getBattery,
  getBatteryStatusesQueryStatus,
  isEatonBattery,
} from '+customer-battery/store/+battery.selectors';
import { Button, ButtonType } from '+shared/components/Button';
import {
  getAnalysisWarnings,
  getWarningMessages,
} from '+shared/store/customer/warnings/warnings.factory';
import { getResolutionForTimeRange } from '+shared/store/site/site.helpers';
import {
  getSiteCellDataQueryStatus,
  getSiteChargeLimitsQueryStatus,
  getSiteLiveState,
} from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';
import { insertIf } from '+utils/array.util';
import { goTo } from '+utils/browser.util';
import { dateUtil } from '+utils/date.util';
import { mapActions } from '+utils/redux/mapActions.util';

import { CustomerWarnings } from '../../../components';
import { AnalysisChartCrosshair } from '../../components/AnalysisChartCrosshair';
import { AnalysisDayChart } from '../../components/AnalysisDayChart';
import { AnalysisTooltipItem } from '../../components/AnalysisTooltipItem';
import { AnalysisActions } from '../../store/+analysis.actions';
import {
  getCurrentDataSeries,
  getInitialDataSeries,
  getIsResolutionChanged,
  getSelectedDataSeriesKeys,
  getSiteMeasurements,
  getSiteMeasurementsQueryStatus,
  hasForecast,
  hasSiteMeasurements,
} from '../../store/+analysis.selector';
import { getAnalysisChartDimensions } from '../../store/helpers/+analysis.helpers';
import {
  chartTooltipStyles,
  handleTooltipTouchEvent,
  seriesKeyTranslationMap,
} from '../../store/helpers/tooltip.helpers';
import { ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';
import { ChartVisibleElements } from '../../store/types/chartVisibleElements.interface';
import { CustomerAnalysisDayToolbar } from '../CustomerAnalysisDayToolbar';
import { prepareDisabledFilters } from '../CustomerAnalysisDayToolbar/CustomerAnalysisDayToolbar.helpers';
import {
  desktopScaleOptions,
  factorizePointToDate,
  getChartErrorMessage,
  getMinSelectedAreaThresholdX,
  getVisibleDataSeriesKeys,
  getVisibleLiveBubblesData,
  getXYFromAreaChartSeries,
  hasSeries,
  mobileScaleOptions,
} from './CustomerAnalysisEnergyFlow.helper';

import './CustomerAnalysisEnergyFlow.component.scss';

interface OwnProps {
  featureFlags: LDFlagSet;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

interface State {
  tooltipEvent?: TooltipEvent;
  dayChartView?: View<any>;
  isChartZoomed: boolean;
  scale: number;
  lastXYSeriePosition: AnalysisLastXYSeriePosition;
}

const mapStateToProps = (state: StoreState) => ({
  initialDataSeries: getInitialDataSeries(state),
  currentDataSeries: getCurrentDataSeries(state),
  selectedDataSeriesKeys: getSelectedDataSeriesKeys(state),
  isResolutionChanged: getIsResolutionChanged(state),
  hasSiteMeasurements: hasSiteMeasurements(state),
  customerQueryStatus: getSelectedCustomerQueryStatus(state),
  siteMeasurementsQueryStatus: getSiteMeasurementsQueryStatus(state),
  batteryStatusesQueryStatus: getBatteryStatusesQueryStatus(state),
  siteChargeLimitsQueryStatus: getSiteChargeLimitsQueryStatus(state),
  siteCellDataQueryStatus: getSiteCellDataQueryStatus(state),
  siteMeasurements: getSiteMeasurements(state),
  battery: getBattery(state),
  isEaton: isEatonBattery(state),
  siteLiveData: getSiteLiveState(state),
  hasForecast: hasForecast(state),
});

const mapDispatchToProps = mapActions({
  clearZoomState: AnalysisActions.clearZoomState,
  setTimeRangeChartZoomDates: AnalysisActions.setCurrentChartDates,
  toggleDataSeries: AnalysisActions.toggleDataSeries,
});

class CustomerAnalysisEnergyFlowComponent extends React.PureComponent<Props, State> {
  private tooltipExtension: DataContainerTooltipExtension;
  private transformExtension: DataContainerTransformExtension;
  private lastXYPositionTimeout: number = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      tooltipEvent: undefined,
      dayChartView: undefined,
      isChartZoomed: false,
      scale: 0,
      lastXYSeriePosition: {
        [EnergyFlowSeriesKey.CONSUMPTION_POWER]: { x: 0, y: 0 },
        [EnergyFlowSeriesKey.PRODUCTION_POWER]: { x: 0, y: 0 },
      },
    };

    this.tooltipExtension = new DataContainerTooltipExtension({
      onTooltipEvent: this.handleTooltipEvent,
    });

    this.transformExtension = new DataContainerTransformExtension({
      scale: {
        limit: {
          x: [1, 10],
        },
        listener: this.handleScale,
        listenerThreshold: 0.001,
      },
    });
  }

  componentDidMount() {
    this.setLastXYPositions();
    this.handleDisabledFilters();

    // NOTE: This is to continuously update the positions because of zooming, fetching live data etc
    // TODO: Consider another way to update it - some listener, callback on zoom etc
    this.lastXYPositionTimeout = window.setInterval(() => {
      this.setLastXYPositions();
    }, 300);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.initialDataSeries !== this.props.initialDataSeries) {
      this.setLastXYPositions();
    }

    if (
      prevProps.siteMeasurementsQueryStatus.pending &&
      this.props.siteMeasurementsQueryStatus.success
    ) {
      this.setZoomState(false);
      this.transformExtension.setScale({ x: 1, y: 1 });
    }

    if (
      !isEqual(
        prevProps.initialDataSeries.selectedDates,
        this.props.initialDataSeries.selectedDates
      )
    ) {
      this.transformExtension.setScale({ x: 1, y: 1 });
    }

    this.handleDisabledFilters();
  }

  componentWillUnmount() {
    this.handleDisabledFilters();
    clearTimeout(this.lastXYPositionTimeout);
  }

  handleTooltipEvent: TooltipEventHandler = (event) => {
    if (this.state.tooltipEvent === event) {
      return;
    }

    if (!Object.values(event.match.values).every((point) => point && isNil(point.y))) {
      this.setState({ tooltipEvent: event });
    }
  };

  handleTooltipPositionChange = (x: number) => (view?: View<any>) => {
    if (this.tooltipExtension && view) {
      this.setState({ dayChartView: view });
      this.tooltipExtension.simulateAbsoluteCanvasPosition(view as any, { x, y: 0 });
    }
  };

  fromPointer: React.PointerEventHandler = ({ nativeEvent }) => {
    const { tooltipEvent } = this.state;

    if (tooltipEvent) {
      this.handleTooltipPositionChange(nativeEvent.pageX - tooltipEvent.pointerEvent.offset.left);
    }
  };

  getZoomAreaTimeRange = (start: XYPoint, end: XYPoint): [Date, Date] => {
    const { isResolutionChanged, initialDataSeries, currentDataSeries } = this.props;
    const [startDate] = isResolutionChanged
      ? currentDataSeries.selectedDates!
      : initialDataSeries.selectedDates;

    const zoomAreaStartDate = dateUtil.add(startDate, Math.floor(start.x), 'minute');
    const zoomAreaEndDate = dateUtil.add(startDate, Math.floor(end.x), 'minute');

    return [zoomAreaStartDate, zoomAreaEndDate];
  };

  shouldResolutionChange = (start: XYPoint, end: XYPoint): boolean => {
    const { isResolutionChanged, initialDataSeries, currentDataSeries } = this.props;
    const { resolution } = isResolutionChanged ? currentDataSeries : initialDataSeries;
    const [initialStartDate, initialEndDate] = initialDataSeries.selectedDates;

    const isForecastFromQuickLink =
      dateUtil.isToday(initialStartDate) &&
      dateUtil.isSameDay(initialEndDate, dateUtil.add(new Date(), 2, 'day'));

    const [zoomAreaStartDate, zoomAreaEndDate] = this.getZoomAreaTimeRange(start, end);
    const zoomAreaResolution = getResolutionForTimeRange(zoomAreaStartDate, zoomAreaEndDate);

    return resolution !== zoomAreaResolution && !isForecastFromQuickLink;
  };

  handleScaleWithRequest = (start: Point, end: Point): void => {
    const [zoomAreaStartDate, zoomAreaEndDate] = this.getZoomAreaTimeRange(start, end);

    this.props.actions.setTimeRangeChartZoomDates([zoomAreaStartDate, zoomAreaEndDate]);
    this.transformExtension.setScale({ x: 1, y: 1 });
  };

  handleScaleWithoutRequest = (scaleX: number, dataContainers: Array<DataContainer<any>>): void => {
    const newScale = Math.floor(Math.log2(scaleX));

    if (this.state.scale !== newScale) {
      dataContainers.forEach((container) => {
        container.setXAxisParameters({
          ...container.getXAxisParameters(),
          tickCount: 1 + (BASE_TICK_COUNT - 1) * Math.pow(2, newScale),
        });
      });

      this.setState({ scale: newScale });
    }
  };

  handleScale: SimpleOnScaleListener = ({ start, end, scaleX, dataContainers }): void => {
    this.setZoomState(scaleX > 1);

    if (start && end && this.shouldResolutionChange(start, end)) {
      this.handleScaleWithRequest(start, end);
    } else {
      this.handleScaleWithoutRequest(scaleX, dataContainers);
    }
  };

  handleScaleReset = (): void => {
    const { isResolutionChanged, actions } = this.props;
    const { isChartZoomed } = this.state;

    if (isResolutionChanged) {
      actions.clearZoomState();
    }

    if (isChartZoomed) {
      this.transformExtension.setScale({ x: 1, y: 1 });
    }
  };

  setZoomState = (isChartZoomed: boolean) => this.setState({ isChartZoomed });

  setLastXYPosition = (seriesKey: EnergyFlowSeriesKey) => {
    const position = getXYFromAreaChartSeries(
      this.state.dayChartView,
      this.props.initialDataSeries.data,
      seriesKey
    );

    if (!position) {
      return;
    }

    this.setState((prevState) => ({
      lastXYSeriePosition: {
        ...prevState.lastXYSeriePosition,
        [seriesKey]: {
          x: position.x,
          // TODO: Introduce isShifted property
          // to move only label, not a dot
          y: position.y,
        },
      },
    }));
  };

  setLastXYPositions = () => {
    this.setLastXYPosition(EnergyFlowSeriesKey.CONSUMPTION_POWER);
    this.setLastXYPosition(EnergyFlowSeriesKey.PRODUCTION_POWER);
  };

  handleMissingDataClick = (event: React.SyntheticEvent<HTMLAnchorElement>): void => {
    goTo(KNOWLEDGE_BASE.customerAnalysisEnergyFlowMissingDataInfo);
  };

  calculateAnalysisChartHeight = (
    startDate: Date,
    endDate: Date
  ): Record<'desktopHeight' | 'mobileHeight', number> => {
    const {
      areaChartHeightMobile,
      areaChartHeightDesktop,
      lineChartHeight,
      bandChartHeight,
      xAxisHeight,
      areaChartMargin,
    } = getAnalysisChartDimensions();
    const chartVisibleElementsMap = this.createChartVisibleElementsMap(startDate, endDate);

    const visibileLineCharts = [
      chartVisibleElementsMap.includes(BatteryStatusesKey.VPP_ACTIVITY),
      chartVisibleElementsMap.includes(BatteryStatusesKey.CHARGE_LIMIT),
      chartVisibleElementsMap.includes(BatteryStatusesKey.CELL_CARE),
    ].filter(Boolean);
    const isBandChartVisible =
      chartVisibleElementsMap.includes(CellDataSeriesKey.TEMPERATURE) ||
      chartVisibleElementsMap.includes(CellDataSeriesKey.VOLTAGE);

    const fullAreaChartHeight = (areaChartHeight: number) =>
      areaChartHeight + xAxisHeight + areaChartMargin;
    const fullBandChartHeight = bandChartHeight + xAxisHeight;

    const lineChartsHeight = visibileLineCharts.length * lineChartHeight;
    const bandChartsHeight = Number(isBandChartVisible) * fullBandChartHeight;

    return {
      desktopHeight:
        fullAreaChartHeight(areaChartHeightDesktop) + lineChartsHeight + bandChartsHeight,
      mobileHeight:
        fullAreaChartHeight(areaChartHeightMobile) + lineChartsHeight + bandChartsHeight,
    };
  };

  isVisible = (key: ChartDataSeriesKey): Visibility =>
    this.props.selectedDataSeriesKeys.includes(key) ? Visibility.VISIBLE : Visibility.INVISIBLE;

  isVppActivityLineChartVisible = (startDate: Date, endDate: Date): Visibility => {
    const isOneDaySelected = dateUtil.getDifference(startDate, endDate, 'hours') <= HOURS_IN_DAY;
    const isVppGraphEnabled = useCountryFeature(CountryFeatureName.VPP_ACTIVITY_GRAPH).isEnabled;

    return isOneDaySelected && isVppGraphEnabled
      ? this.isVisible(BatteryStatusesKey.VPP_ACTIVITY)
      : Visibility.INVISIBLE;
  };

  isCellCareLineChartVisible = (startDate: Date, endDate: Date): Visibility => {
    const isOneDaySelected = dateUtil.getDifference(startDate, endDate, 'hours') <= HOURS_IN_DAY;
    const isCellCareDataEmpty = this.props.isResolutionChanged
      ? isEmpty(this.props.currentDataSeries.data[BatteryStatusesKey.CELL_CARE])
      : isEmpty(this.props.initialDataSeries.data[BatteryStatusesKey.CELL_CARE]);

    return isOneDaySelected && !isCellCareDataEmpty
      ? this.isVisible(BatteryStatusesKey.CELL_CARE)
      : Visibility.INVISIBLE;
  };

  isBandChartVisible = (
    startDate: Date,
    endDate: Date,
    bandChartSeriesKey: CellDataSeriesKey
  ): Visibility =>
    dateUtil.getDifference(startDate, endDate, 'days') < DAYS_IN_WEEK
      ? this.isVisible(bandChartSeriesKey)
      : Visibility.INVISIBLE;

  isChartSerieDataEmpty = (key: ChartDataSeriesKey): boolean =>
    isEmpty(this.props.initialDataSeries.data[key]);

  createChartVisibleElementsMap = (startDate: Date, endDate: Date): ChartVisibleElements => {
    const featureFlags = this.props.featureFlags;

    const isBatteryChargeLimitEnabled = featureFlags.chargeLimits;

    const chargeLimitLineChartVisibility =
      isBatteryChargeLimitEnabled &&
      !this.isChartSerieDataEmpty(BatteryStatusesKey.CHARGE_LIMIT) &&
      this.isVisible(BatteryStatusesKey.CHARGE_LIMIT);
    const vppActivityLineChartVisibility = this.isVppActivityLineChartVisible(startDate, endDate);
    const cellCareLineChartVisibility = this.isCellCareLineChartVisible(startDate, endDate);
    const cellTemperatureBandChartVisibility = this.isBandChartVisible(
      startDate,
      endDate,
      CellDataSeriesKey.TEMPERATURE
    );
    const cellVoltageBandChartVisibility = this.isBandChartVisible(
      startDate,
      endDate,
      CellDataSeriesKey.VOLTAGE
    );

    return [
      ...insertIf(
        chargeLimitLineChartVisibility === Visibility.VISIBLE,
        BatteryStatusesKey.CHARGE_LIMIT
      ),
      ...insertIf(
        vppActivityLineChartVisibility === Visibility.VISIBLE,
        BatteryStatusesKey.VPP_ACTIVITY
      ),
      ...insertIf(cellCareLineChartVisibility === Visibility.VISIBLE, BatteryStatusesKey.CELL_CARE),
      ...insertIf(
        cellTemperatureBandChartVisibility === Visibility.VISIBLE,
        CellDataSeriesKey.TEMPERATURE
      ),
      ...insertIf(cellVoltageBandChartVisibility === Visibility.VISIBLE, CellDataSeriesKey.VOLTAGE),
    ];
  };

  handleDisabledFilters = () => {
    const {
      actions,
      isResolutionChanged,
      isEaton,
      initialDataSeries,
      currentDataSeries,
      selectedDataSeriesKeys,
    } = this.props;
    const initialSelectedDates = initialDataSeries.selectedDates;
    const currentSelectedDates = currentDataSeries.selectedDates;
    const [zoomBasedStartDate, zoomBasedEndDate] = isResolutionChanged
      ? currentSelectedDates!
      : initialSelectedDates;

    const disabledFilters = Object.keys(
      prepareDisabledFilters(
        zoomBasedStartDate,
        zoomBasedEndDate,
        isResolutionChanged,
        initialDataSeries.data,
        currentDataSeries.data,
        isEaton
      )
    );

    // INFO: if we decide to include more filters in this deselect logic please refactor below code
    // to more scalable solution
    const shouldCellTemperatureBeDisabled = disabledFilters.includes(CellDataSeriesKey.TEMPERATURE);
    const isCellTemperatureFilterActive = selectedDataSeriesKeys.includes(
      CellDataSeriesKey.TEMPERATURE
    );
    const shouldCellVoltageBeDisabled = disabledFilters.includes(CellDataSeriesKey.VOLTAGE);
    const isCellVoltageFilterActive = selectedDataSeriesKeys.includes(CellDataSeriesKey.VOLTAGE);

    if (shouldCellTemperatureBeDisabled && isCellTemperatureFilterActive) {
      actions.toggleDataSeries(CellDataSeriesKey.TEMPERATURE);
    }
    if (shouldCellVoltageBeDisabled && isCellVoltageFilterActive) {
      actions.toggleDataSeries(CellDataSeriesKey.VOLTAGE);
    }
  };

  render() {
    const {
      initialDataSeries,
      currentDataSeries,
      isResolutionChanged,
      selectedDataSeriesKeys,
      hasSiteMeasurements,
      siteMeasurementsQueryStatus,
      batteryStatusesQueryStatus,
      siteChargeLimitsQueryStatus,
      siteCellDataQueryStatus,
      siteMeasurements,
      battery,
      isEaton,
      siteLiveData,
      customerQueryStatus,
      hasForecast,
    } = this.props;
    const { tooltipEvent, dayChartView, isChartZoomed, lastXYSeriePosition } = this.state;

    const selectedDates =
      isResolutionChanged && currentDataSeries.selectedDates
        ? currentDataSeries.selectedDates
        : initialDataSeries.selectedDates;
    const [startDate, endDate] = selectedDates;

    const dataSeries =
      isResolutionChanged && currentDataSeries.data[EnergyFlowSeriesKey.CONSUMPTION_POWER].length
        ? currentDataSeries.data
        : initialDataSeries.data;

    const visibleDataSeriesKeys = getVisibleDataSeriesKeys(
      tooltipEvent && tooltipEvent.match,
      selectedDataSeriesKeys
    );

    const chartDimensions = this.calculateAnalysisChartHeight(...selectedDates);

    const analysisWarnings = compose(
      getWarningMessages,
      getAnalysisWarnings
    )({ siteMeasurements, battery });

    const canShowForecast = !!(siteLiveData && siteLiveData.online) && hasForecast;
    const canShowChart =
      customerQueryStatus.pending ||
      siteMeasurementsQueryStatus.pending ||
      hasSiteMeasurements ||
      canShowForecast;
    const isResetButtonVisible =
      (isResolutionChanged || isChartZoomed) && siteMeasurementsQueryStatus.success;
    const isLessThanOneYearSelected =
      dateUtil.getDifference(startDate, endDate, 'days') <= DAYS_IN_YEAR;
    const isMeasurementsError = siteMeasurementsQueryStatus.error;

    const isLoading =
      customerQueryStatus.pending ||
      siteMeasurementsQueryStatus.pending ||
      batteryStatusesQueryStatus.pending ||
      siteChargeLimitsQueryStatus.pending ||
      siteCellDataQueryStatus.pending;

    return (
      <section className={'c-customer-analysis-energy-flow'}>
        <CustomerWarnings warnings={analysisWarnings} />
        <Card header={<CustomerAnalysisDayToolbar isLoading={isLoading} />} noHeaderGap={true}>
          {canShowChart && isLessThanOneYearSelected && !isMeasurementsError ? (
            <>
              <Media query={MediaQuery.UP_MD}>
                <div
                  className={classNames('c-customer-analysis-energy-flow__reset-wrapper', {
                    'c-customer-analysis-energy-flow__reset-wrapper--visible': isResetButtonVisible,
                  })}
                >
                  <Button
                    label={
                      <>
                        <Icon.Angle className={'button-label-arrow-left'} />
                        {I18n.t(T.customerSingle.analysis.dayChart.resetZoom)}
                      </>
                    }
                    type={ButtonType.TERTIARY}
                    onClick={this.handleScaleReset}
                  />
                </div>
              </Media>
              <div
                className={'c-customer-analysis-energy-flow__content'}
                onTouchMove={(e: React.TouchEvent) =>
                  handleTooltipTouchEvent(e, dayChartView, this.tooltipExtension, tooltipEvent)
                }
              >
                <div className={'c-customer-analysis-energy-flow__tooltip-wrapper'}>
                  <AnalysisTooltip
                    type={'horizontal'}
                    isVisible={tooltipEvent && !isLoading}
                    header={
                      <div className={'c-customer-analysis-energy-flow__tooltip-header'}>
                        {factorizePointToDate('tooltip')(
                          ...selectedDates,
                          battery?.timeZone ?? undefined
                        )(tooltipEvent?.match.primary.x)}
                      </div>
                    }
                    footer={
                      <div className={'c-customer-analysis-energy-flow__tooltip-footer'}>
                        {I18n.t(T.customerSingle.analysis.dayChart.missingData.headline)}
                        <Button
                          label={I18n.t(T.customerSingle.analysis.dayChart.missingData.cta)}
                          type={ButtonType.TERTIARY}
                          onClick={this.handleMissingDataClick}
                        />
                      </div>
                    }
                  >
                    {prepareTooltipData(
                      tooltipEvent?.match.values,
                      seriesKeyTranslationMap,
                      visibleDataSeriesKeys,
                      chartTooltipStyles
                    ).map((props) => (
                      <AnalysisTooltipItem key={props.seriesKey} {...props} />
                    ))}
                  </AnalysisTooltip>
                </div>
                <div className={'c-customer-analysis-energy-flow__container'}>
                  <Media query={MediaQuery.UP_MD}>
                    {(isDesktop) => (
                      <AnalysisDayChart
                        selectedDates={selectedDates}
                        dataSeries={dataSeries}
                        selectedDataSeriesKeys={selectedDataSeriesKeys}
                        tooltipExtension={this.tooltipExtension}
                        transformExtension={this.transformExtension}
                        scaleOptions={
                          isDesktop
                            ? desktopScaleOptions(
                                getMinSelectedAreaThresholdX(startDate, endDate, isEaton)
                              )
                            : mobileScaleOptions
                        }
                        chartHeight={
                          isDesktop ? chartDimensions.desktopHeight : chartDimensions.mobileHeight
                        }
                        isChartZoomed={isChartZoomed}
                        isResolutionChanged={isResolutionChanged}
                        isLoading={isLoading}
                        isEaton={isEaton}
                        chartVisibleElements={this.createChartVisibleElementsMap(
                          startDate,
                          endDate
                        )}
                        onMount={this.handleTooltipPositionChange}
                      />
                    )}
                  </Media>
                  {hasSeries(initialDataSeries.data) && (
                    <AnalysisLiveBubbles
                      data={getVisibleLiveBubblesData(
                        selectedDataSeriesKeys,
                        lastXYSeriePosition,
                        siteLiveData,
                        selectedDates,
                        isEaton,
                        isLoading
                      )}
                    />
                  )}
                </div>
                <AnalysisChartCrosshair
                  tooltipEvent={tooltipEvent}
                  isVisible={tooltipEvent && !isLoading}
                  offset={-18}
                  {...chartDimensions}
                />
              </div>
            </>
          ) : (
            <InfoBanner
              icon={<Icon.BrokenBattery />}
              title={getChartErrorMessage(isLessThanOneYearSelected).title}
              subtitle={getChartErrorMessage(isLessThanOneYearSelected).subtitle}
            />
          )}
        </Card>
      </section>
    );
  }
}

export const CustomerAnalysisEnergyFlow = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAnalysisEnergyFlowComponent);
