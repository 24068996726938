import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';
import { CountryCode } from 'src/types/country.type';

import { CUSTOMER_ROUTES, DASHBOARD_ROUTES, LEAD_ROUTES, PATHS, SETUP_ROUTES } from '+app/router';
import { mapActions } from '+app/utils';
import { getStringifiedStatuses } from '+case/containers/CaseList/CaseList.helper';
import { CountryFeatureName } from '+config/countryFlags';
import { HELP_SECTION_LINK } from '+config/env.config';
import { NavLinkColor } from '+shared/components/NavLink/NavLink.helpers';
import { RestrictedToRoles } from '+shared/components/RestrictedToRoles';
import { YourSonnenWorldTab } from '+shared/components/YourSonnenWorldTab/YourSonnenWorldTab';
import { useLDFeatureToggle } from '+shared/hooks/useLDFeatureToggle';
import {
  getPartnerApplications,
  isAuthenticated,
  isImpersonated,
} from '+shared/store/auth/auth.selectors';
import { contactsAllowedRoles } from '+shared/store/contact';
import { isNavOpened } from '+shared/store/layout';
import { leadTabAllowedRoles, setupLeadTabAllowedRoles } from '+shared/store/lead';
import {
  getUserCountryCode,
  getUserProfileSalesforceUserId,
} from '+shared/store/user/user.selectors';
import { mapCountryCodeToMarket, Market } from '+utils/market.util';

import { Nav, NavLink, NavUser, RolloutLimiter } from '../../components';
import { caseDefaultFilters } from '../../store/case';
import { LayoutActions } from '../../store/layout';
import { StoreState } from '../../store/store.interface';
import {
  getUserCompanyLogo,
  getUserProfile,
  getUserProfileRoles,
} from '../../store/user/user.selectors';

const NEW_HELP_SECTION_MARKETS = [Market.DE, Market.AT, Market.CH];

const getHelpLink = (userMarket: Market) =>
  NEW_HELP_SECTION_MARKETS.includes(userMarket)
    ? HELP_SECTION_LINK[process.env.REACT_APP_CONFIG!]
    : PATHS.HELP();

const mapDispatchToProps = mapActions({
  toggleNavigation: LayoutActions.toggleNavigation,
});

const mapStateToProps = (state: StoreState) => ({
  location: state.router.location,
  userProfile: getUserProfile(state),
  userCountry: getUserCountryCode(state) ?? CountryCode.DE,
  isAuthenticated: isAuthenticated(state),
  isNavMobileActive: isNavOpened(state),
  userRoles: getUserProfileRoles(state),
  companyLogo: getUserCompanyLogo(state),
  partnerApplications: getPartnerApplications(state),
  isImpersonated: isImpersonated(state),
  userId: getUserProfileSalesforceUserId(state),
});

type NavItem = {
  userMarket: Market;
  userProfile: ReturnType<typeof getUserProfile>;
};

type LayoutNavProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

const LayoutNavComponent: React.FC<LayoutNavProps> = ({
  isNavMobileActive,
  isAuthenticated,
  actions,
  location,
  companyLogo,
  userProfile,
  userCountry,
  partnerApplications,
  isImpersonated,
  userId,
}) => {
  const isSpanishMarketEnabled = useLDFeatureToggle('spanishMarket');
  const userMarket = mapCountryCodeToMarket(userCountry, isSpanishMarketEnabled) ?? Market.DE;

  const renderNavItems = ({
    location,
    userProfile,
    userMarket,
  }: Pick<LayoutNavProps, 'location' | 'userProfile'> & NavItem): React.ReactNode => {
    const {
      _salessolution_: { leads },
      home,
      setup,
      customers,
      cases,
      help,
      myAccount,
      contacts,
      logOut,
    } = T.general.navigation;

    return (
      <>
        <NavLink
          action={PATHS.DASHBOARD()}
          matchMultiplePaths={{
            paths: DASHBOARD_ROUTES,
            location,
          }}
          navLinkColor={NavLinkColor.WHITE}
          dataTestId="home-tab"
        >
          {I18n.t(home)}
        </NavLink>

        <RolloutLimiter countryFeatureName={CountryFeatureName.LEAD_PHASE}>
          <RestrictedToRoles
            allowedRoles={leadTabAllowedRoles}
            userRoles={isNil(userProfile) ? [] : userProfile.roles}
          >
            <NavLink
              action={PATHS.LEADS()}
              matchMultiplePaths={{
                paths: LEAD_ROUTES,
                location,
              }}
              navLinkColor={NavLinkColor.WHITE}
              dataTestId="leads-tab"
            >
              {I18n.t(leads)}
            </NavLink>
          </RestrictedToRoles>
        </RolloutLimiter>

        <RolloutLimiter countryFeatureName={CountryFeatureName.SETUP_PHASE}>
          <RestrictedToRoles
            allowedRoles={setupLeadTabAllowedRoles}
            userRoles={isNil(userProfile) ? [] : userProfile.roles}
          >
            <NavLink
              action={PATHS.SETUP_LEADS()}
              matchMultiplePaths={{
                paths: SETUP_ROUTES,
                location,
              }}
              navLinkColor={NavLinkColor.WHITE}
              dataTestId="setup-tab"
            >
              {I18n.t(setup)}
            </NavLink>
          </RestrictedToRoles>
        </RolloutLimiter>

        <NavLink
          action={PATHS.CUSTOMERS()}
          matchMultiplePaths={{
            paths: CUSTOMER_ROUTES,
            location,
          }}
          navLinkColor={NavLinkColor.WHITE}
          dataTestId="customers-tab"
        >
          {I18n.t(customers)}
        </NavLink>

        <NavLink
          action={PATHS.CASES({ status: getStringifiedStatuses(caseDefaultFilters) })}
          navLinkColor={NavLinkColor.WHITE}
          dataTestId="cases-tab"
        >
          {I18n.t(cases)}
        </NavLink>

        <YourSonnenWorldTab market={userMarket} />

        <NavLink
          action={getHelpLink(userMarket)}
          navLinkColor={NavLinkColor.WHITE}
          dataTestId="help-tab"
        >
          {I18n.t(help)}
        </NavLink>

        <NavUser
          user={userProfile}
          navItems={
            <>
              <NavLink
                action={'/account'}
                type={'mobile'}
                border={true}
                navLinkColor={NavLinkColor.WHITE}
                dataTestId="my-account-subtab"
              >
                {I18n.t(myAccount)}
              </NavLink>
              <RestrictedToRoles
                allowedRoles={contactsAllowedRoles}
                userRoles={isNil(userProfile) ? [] : userProfile.roles}
              >
                <NavLink
                  action={'/contacts'}
                  type={'mobile'}
                  border={true}
                  navLinkColor={NavLinkColor.WHITE}
                  dataTestId="role-management-subtab"
                >
                  {I18n.t(contacts)}
                </NavLink>
              </RestrictedToRoles>
              <NavLink
                action={PATHS.LOGOUT()}
                type={'mobile'}
                border={true}
                navLinkColor={NavLinkColor.WHITE}
                dataTestId="log-out-btn"
              >
                {I18n.t(logOut)}
              </NavLink>
            </>
          }
        />
      </>
    );
  };

  return (
    <Nav
      isSticky={true}
      isMobileNavActive={isNavMobileActive}
      navItems={isAuthenticated && renderNavItems({ location, userProfile, userMarket })}
      onToggle={actions.toggleNavigation}
      companyLogo={companyLogo}
      partnerApplications={partnerApplications}
      impersonateUserId={isImpersonated ? userId : undefined}
    />
  );
};

export const LayoutNav = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LayoutNavComponent));
