import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import {
  AnalysisLastXYSeriePosition,
  EnergyFlowSeries,
  EnergyFlowSeriesKey,
  SharedChartColors,
} from '@sonnen/shared-web';

import {
  AreaChartView,
  DataContainer,
  DataContainerTransformExtensionOptions,
  XYPoint,
  YValuesMatch,
} from '@kanva/charts';
import { View } from '@kanva/core';
import { isEmpty, isNil, last } from 'lodash/fp';
import { DeepPartial } from 'redux';

import { getResolutionForTimeRange, isLiveDataDelayed } from '+shared/store/site/site.helpers';
import { SiteLiveState } from '+shared/store/site/types/siteLiveState.interface';
import { dateUtil } from '+utils/date.util';

import { analysisPointAccessor } from '../../store/helpers/+analysis.helpers';
import { ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';

export const getUnixDateFromNormalizedX = (normalizedX: number, unixStartOfDay: number) =>
  normalizedX * 60 + unixStartOfDay;

export const getVisibleDataSeriesKeys = (
  tooltipEventMatch: YValuesMatch | undefined,
  selectedDataSeriesKeys: ChartDataSeriesKey[]
): ChartDataSeriesKey[] => {
  if (!tooltipEventMatch) {
    return [];
  }

  const isRealDataAvailable = realDataAvailable(selectedDataSeriesKeys, tooltipEventMatch);

  const selectedSeriesKeys: ChartDataSeriesKey[] = isRealDataAvailable
    ? Object.assign([], selectedDataSeriesKeys)
    : [];

  if (
    !isRealDataAvailable &&
    selectedDataSeriesKeys.includes(EnergyFlowSeriesKey.CONSUMPTION_POWER)
  ) {
    selectedSeriesKeys.push(EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER);
  }

  if (
    !isRealDataAvailable &&
    selectedDataSeriesKeys.includes(EnergyFlowSeriesKey.PRODUCTION_POWER)
  ) {
    selectedSeriesKeys.push(EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER);
  }

  return Object.entries(tooltipEventMatch.values)
    .filter(([key]) => selectedSeriesKeys.includes(key as EnergyFlowSeriesKey))
    .map(([key]) => key) as EnergyFlowSeriesKey[];
};

const realDataAvailable = (
  selectedDataSeriesKeys: ChartDataSeriesKey[],
  tooltipEventMatch: YValuesMatch
) => selectedDataSeriesKeys.some((key) => tooltipEventMatch.values[key] !== undefined);

export const getXYFromAreaChartSeries = (
  // TODO: Consider this chartView type
  chartView: View<any> | AreaChartView | undefined,
  dataSeries: EnergyFlowSeries,
  serieKey: EnergyFlowSeriesKey
) => {
  const serie: XYPoint[] = dataSeries[serieKey];

  if (!chartView || !serie || !last(serie)) {
    return;
  }

  const x = last(serie)!.x || 0;
  const y = last(serie)!.y || 0;
  const lastXYPoint = analysisPointAccessor({ x, y }, serie.length, serie);

  const canvasPosition = (chartView as AreaChartView).getCanvasPositionForPoint(lastXYPoint);
  const offset = 19; // Note: offset is set due to paddings and margins in the charts

  return {
    x: canvasPosition.absoluteX,
    y:
      isNil(canvasPosition.absoluteY) || isNaN(canvasPosition.absoluteY)
        ? 0
        : canvasPosition.absoluteY + offset,
  };
};

const hasLastXYSeriePosition = (lastXYSeriePosition: XYPoint<number> | undefined) =>
  lastXYSeriePosition && lastXYSeriePosition!.x !== 0 && lastXYSeriePosition!.y !== 0;

export const getVisibleLiveBubblesData = (
  selectedDataSeriesKeys: ChartDataSeriesKey[],
  lastXYSeriePosition: AnalysisLastXYSeriePosition,
  siteLiveData: SiteLiveState | undefined,
  [startDate, endDate]: [Date, Date],
  isEaton: boolean,
  isLoading: boolean
) => {
  const isSeriesVisible = createIsSeriesVisible(selectedDataSeriesKeys);

  const latestLiveDate: string | undefined = siteLiveData?.timestamp;
  const isLiveDelayed = isLiveDataDelayed(latestLiveDate);
  const isTodayInRange =
    dateUtil.isSameOrBefore(startDate, dateUtil.now(), 'day') &&
    dateUtil.isSameOrBefore(dateUtil.now(), endDate, 'day');
  const isRangeLimitedToOneDay = dateUtil.getDifference(startDate, endDate, 'days') < 1;

  if (isLiveDelayed || isEaton || isLoading || !isTodayInRange || !isRangeLimitedToOneDay) {
    return [];
  }

  const visibleBubblesData = [];
  const lastXYSeriePositionForProduction =
    lastXYSeriePosition[EnergyFlowSeriesKey.PRODUCTION_POWER];
  const lastXYSeriePositionForConsumption =
    lastXYSeriePosition[EnergyFlowSeriesKey.CONSUMPTION_POWER];

  const isReversed = Boolean(
    siteLiveData && siteLiveData.timestamp && new Date(siteLiveData.timestamp).getHours() >= 20
  );

  if (
    hasLastXYSeriePosition(lastXYSeriePositionForProduction) &&
    isSeriesVisible(EnergyFlowSeriesKey.PRODUCTION_POWER)
  ) {
    visibleBubblesData.push({
      color: SharedChartColors.ProductionStroke,
      value: (siteLiveData && siteLiveData.productionPower) || '-',
      position: lastXYSeriePositionForProduction || { x: 0, y: 0 },
      isReversed,
    });
  }

  if (
    hasLastXYSeriePosition(lastXYSeriePositionForConsumption) &&
    isSeriesVisible(EnergyFlowSeriesKey.CONSUMPTION_POWER)
  ) {
    visibleBubblesData.push({
      color: SharedChartColors.ConsumptionStroke,
      value: (siteLiveData && siteLiveData.consumptionPower) || '-',
      position: lastXYSeriePositionForConsumption || { x: 0, y: 0 },
      isReversed,
    });
  }

  return visibleBubblesData;
};

export const createIsSeriesVisible =
  (selectedDataSeriesKeys: ChartDataSeriesKey[]) => (series: ChartDataSeriesKey) =>
    selectedDataSeriesKeys.some((s) => s === series);

export const resolveAreaXPosition =
  (dataSeriesKey: string) =>
  (dataContainer: DataContainer<any>, [startDate, endDate]: [Date, Date]): number => {
    const series = dataContainer.getDataSeries(dataSeriesKey);
    if (isNil(series)) {
      return 0;
    }

    const data = series!.data.filter((point) => !isNil(point.y));
    if (isEmpty(data)) {
      return 0;
    }
    const isChartFullyFilled = series.data.length === 24 * 60;
    const isTodayAndOneDayRange =
      dateUtil.isToday(endDate) && dateUtil.getDifference(startDate, endDate, 'days') < 1;

    return isTodayAndOneDayRange && !isChartFullyFilled
      ? last(data)!.x
      : data[Math.floor(data.length / 2)].x + 1;
  };

export const hasSeries = (series?: EnergyFlowSeries) =>
  series ? !Object.values(series).every(isEmpty) : false;

export const mobileScaleOptions: DeepPartial<DataContainerTransformExtensionOptions> = {
  scale: {
    drag: true,
    scroll: false,
    selectArea: false,
  },
};

export const desktopScaleOptions = (
  minSelectedAreaThresholdX: number
): DeepPartial<DataContainerTransformExtensionOptions> => ({
  scale: {
    drag: false,
    scroll: false,
    selectArea: true,
    minSelectedAreaThreshold: { x: minSelectedAreaThresholdX, y: 0 },
  },
});

export const getMinSelectedAreaThresholdX = (start: Date, end: Date, isEaton?: boolean): number => {
  const resolution = getResolutionForTimeRange(start, end, isEaton);

  switch (resolution) {
    case '1-minute':
      return 50;
    case '3-minutes':
      return 20;
    case '5-minutes':
      return 10;
    case '1-hour':
      return 1;
    case '3-hours':
      return 1;
    default:
      return 50;
  }
};

export const getLablesValueRoundToByResolution = (
  start: Date,
  end: Date,
  isEaton?: boolean
): number => {
  const resolution = getResolutionForTimeRange(start, end, isEaton);

  switch (resolution) {
    case '1-minute':
      return 1;
    case '3-minutes':
      return 3;
    case '5-minutes':
      return 10;
    case '1-hour':
    case '3-hours':
      return 60;
    default:
      return 5;
  }
};

export const factorizePointToDate =
  (type: 'tooltip' | 'xAxis') =>
  (startDate: Date, endDate: Date, timeZone?: string) =>
  (x: number | undefined): string => {
    if (isNil(x)) {
      return '-';
    }

    const currentDate = dateUtil.add(startDate, x, 'minute');
    const currentDateTimeZoneAbbreviation = timeZone
      ? dateUtil.getTimezoneAbbreviation(currentDate, timeZone)
      : '';

    switch (type) {
      case 'xAxis':
        if (dateUtil.getDifference(startDate, endDate, 'days') >= 1) {
          return dateUtil.format(currentDate, 'Do[\xa0]MMM[\xa0]HH:mm');
        }

        return dateUtil.format(currentDate, 'HH:mm');
      case 'tooltip':
      default:
        return (
          dateUtil.format(currentDate, 'Do[\xa0]MMM[\xa0]HH:mm[\xa0]') +
          currentDateTimeZoneAbbreviation
        );
    }
  };

export const getChartErrorMessage = (
  isLessThanOneYearSelected: boolean
): { title: string; subtitle: string } => {
  if (!isLessThanOneYearSelected) {
    return {
      title: I18n.t(T.customerSingle.analysis.dayChart.overAYearMsg),
      subtitle: '',
    };
  }

  return {
    title: I18n.t(T.customerSingle.analysis.dayChart.noDataHeadline),
    subtitle: I18n.t(T.customerSingle.analysis.dayChart.noDataHeadline),
  };
};
