import * as React from 'react';
import { Tooltip } from 'react-tooltip';

import * as classNames from 'classnames';

import { Info } from '+shared/basicComponents/Icons';

import './BatteryOperationsHeader.component.scss';

export type ExtendedHeaderLabelProps = {
  text: string;
  variant: 'blue' | 'yellow' | 'gray' | 'green' | 'red';
  tooltip?: string;
};

interface BatteryOperationsHeaderProps {
  title: string;
  label?: ExtendedHeaderLabelProps;
}

const isLabelExtended = (label: ExtendedHeaderLabelProps): label is ExtendedHeaderLabelProps => {
  return typeof label !== 'string' && 'text' in label && 'variant' in label;
};

export const BatteryOperationsHeader: React.FC<BatteryOperationsHeaderProps> = ({
  title,
  label,
}) => {
  return (
    <div className={'c-battery-operations-header'}>
      <div className={'c-battery-operations-header__text'}>{title}</div>
      {label && (
        <>
          {isLabelExtended(label) ? (
            label.tooltip ? (
              <ExtendedHeaderLabelWithInfo
                text={label.text}
                variant={label.variant}
                tooltip={label.tooltip}
              />
            ) : (
              <ExtendedHeaderLabel text={label.text} variant={label.variant} />
            )
          ) : (
            <span
              className={classNames(
                'c-battery-operations-header__label',
                'c-battery-operations-header__label--info'
              )}
            >
              {label}
            </span>
          )}
        </>
      )}
    </div>
  );
};

const ExtendedHeaderLabelWithInfo: React.FC<ExtendedHeaderLabelProps> = (label) => (
  <span data-tooltip-id={label.tooltip}>
    <ExtendedHeaderLabel text={label.text} variant={label.variant} />
    <Info className={'c-battery-operations-header__info'} />
    <Tooltip id={label.tooltip} content={label.tooltip} />
  </span>
);

const ExtendedHeaderLabel: React.FC<ExtendedHeaderLabelProps> = (label) => (
  <span
    className={classNames('c-battery-operations-header__label', {
      [`c-battery-operations-header__label--${label.variant}`]: true,
    })}
  >
    {label.text}
  </span>
);
