import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import { isNil } from 'lodash';

import { BatteryIntelligentCharging } from '+shared/store/battery/types/battery.interface';

import type { ExtendedHeaderLabelProps } from '../../components/BatteryOperationsHeader';

export interface IntelligentChargingForm {
  isActive: boolean;
}

export enum IntelligentChargingFormKeys {
  IS_ACTIVE = 'isActive',
}

export const defaultValues: IntelligentChargingForm = {
  isActive: false,
};

export const getBatteryCharging = (value?: BatteryIntelligentCharging): boolean => value === 'on';

const { headlineWhenActive, headlineWhenInactive, statusPending, statusOff, statusOn } =
  T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement;

export const getWidgetTitle = (value?: BatteryIntelligentCharging): string => {
  return I18n.t(getBatteryCharging(value) ? headlineWhenActive : headlineWhenInactive);
};

export const mapBatteryIntelligentChargingToLabel = (
  isPending: boolean,
  isPvFeedInLimitSet: boolean,
  batteryIntelligentCharging?: BatteryIntelligentCharging
): ExtendedHeaderLabelProps | undefined => {
  if (isNil(batteryIntelligentCharging) || !isPvFeedInLimitSet) return;

  if (getBatteryCharging(batteryIntelligentCharging)) {
    return { text: I18n.t(statusOn), variant: 'blue' };
  }

  return {
    text: I18n.t(isPending ? statusPending : statusOff),
    variant: 'gray',
  };
};

export const mapNotAvailableReasonToTooltip = (isEaton: boolean): string =>
  isEaton
    ? I18n.t(T.customerSingle.batteryDetails.batteryOperations.notAvailableTooltip)
    : I18n.t(
        T.customerSingle.batteryDetails.batteryOperations.intelligentChargingManagement
          .notAvailableWhenNoFeedInLimit
      );
