import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';

import './YourSonnenWorldTrigger.scss';

export const YourSonnenWorldTrigger = () => (
  <div className="your-sonnen-world-trigger">
    <div className="your-sonnen-world-trigger__content">
      <div className="your-sonnen-world-trigger__content-item">
        {I18n.t(T.general.navigation.sonnenWorld)}
      </div>
    </div>
    <div className="your-sonnen-world-trigger__arrow" />
  </div>
);
