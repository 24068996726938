import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { T } from '@sonnen/shared-i18n/service';
import { breakpointDown, Status, StatusType, TableCell } from '@sonnen/shared-web';

import { SetupLeadModal } from '+app/+lead/components/SetupLeadModal/SetupLeadModal';
import { mapActions } from '+app/utils';
import { LeadListBattery, LeadListLocation, SetupLeadStatuses } from '+lead/+list/components';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { isLeadSonnenNowInterested } from '+lead/store/+lead.helper';
import { getActiveStatusManagementStatuses } from '+setupTool/store/+setupTool.helpers';
import { ButtonDirection } from '+shared/components';
import { isModalOpen, LayoutActions, ModalId } from '+shared/store/layout';
import { Lead } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';

import { LeadName } from '../LeadName/LeadName';

import './SetupLeadListTableItem.component.scss';

const { list, boc } = T.lead;
const cls = 'c-lead-list-table-item';
const cell = `${cls}__cell`;
const setup = `${cell}--setup`;

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
});

const mapDispatchToProps = mapActions({
  changeLeadStatus: LeadPageActions.changeLeadStatus,
  toggleModal: LayoutActions.toggleModal,
});

interface OwnProps {
  item: Lead;
  onLeadClick: (leadId: string) => void;
  onLeadOfferClick: (leadId: string) => void;
  userCompanyName: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

export const SetupLeadListTableItemComponent: React.FC<Props> = ({
  actions,
  item,
  onLeadClick,
  onLeadOfferClick,
  userCompanyName,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { id, status, isNewlyAssigned } = item;

  useEffect(() => {
    const modalId = id as ModalId;
    actions.toggleModal(modalOpen, modalId);
  }, [modalOpen]);

  const openModal = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setModalOpen(true);
  };

  const configurationButton = () => (
    <button
      className={`${cls}__round-btn-wrapper`}
      data-testid="start-setup-configuration button"
      onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onLeadOfferClick(id);
      }}
    >
      <p>{I18n.t(list._salessolution_.startSetupConfigurationButton)}</p>
      <div
        className={`c-round-button c-round-button--info ${cls}__round-btn`}
        style={{ '--size': 22, '--direction': ButtonDirection.RIGHT } as React.CSSProperties}
      >
        <i className="c-round-button__inner">
          <span className="icofont-angle" />
        </i>
      </div>
    </button>
  );

  return (
    <>
      {modalOpen && (
        <SetupLeadModal
          closeModal={() => setModalOpen(false)}
          modalOpen={modalOpen}
          lead={item}
          userCompanyName={userCompanyName}
        />
      )}

      <div className={`${cls} ${setup}`} onClick={() => onLeadClick(id)}>
        <Media query={{ maxWidth: breakpointDown('LG') }}>
          {(isMedium: boolean) =>
            isMedium ? (
              <>
                <TableCell className={`${cell} ${cell}--mobile-top`}>
                  <LeadName lead={item} />
                  <LeadListLocation item={item} />
                  <LeadListBattery item={item} />
                </TableCell>

                <TableCell className={`${cell} ${cell}--mobile-bottom`}>
                  <SetupLeadStatuses
                    statuses={getActiveStatusManagementStatuses(status)}
                    openModal={openModal}
                  />
                  {configurationButton()}
                </TableCell>
              </>
            ) : (
              <>
                <TableCell className={`${cell} ${setup}-name`}>
                  <LeadName lead={item} />
                </TableCell>
                <TableCell className={`${cell} ${setup}-location`}>
                  <LeadListLocation item={item} />
                </TableCell>
                <TableCell className={`${cell} ${setup}-battery`}>
                  <LeadListBattery item={item} />
                </TableCell>
                <TableCell className={`${cell} ${setup}-status`}>
                  <SetupLeadStatuses
                    statuses={getActiveStatusManagementStatuses(status)}
                    openModal={openModal}
                  />
                </TableCell>
                <TableCell className={`${cell} ${setup}-configuration`}>
                  {configurationButton()}
                </TableCell>
              </>
            )
          }
        </Media>
      </div>

      {isNewlyAssigned && (
        <Status type={StatusType.SUCCESS} label={I18n.t(list._setuptool_.newLead)} />
      )}

      {isLeadSonnenNowInterested(item) && (
        <Status
          type={StatusType.WARNING}
          label={I18n.t(boc._salessolution_.additionalDetails.productInterest.sonnenNow)}
        />
      )}
    </>
  );
};

export const SetupLeadListTableItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupLeadListTableItemComponent);
